import axiosInstance from "@/plugins/axios";
import store from "..";

export default {


    async login({ commit }, credentials) {
        try {
            const response = await axiosInstance.post('/login', credentials);

            // Check if login was successful
            if (response) {

                return response.data
            }
        } catch (error) {
            console.log(error)
            commit('setError', 'An error occurred during login.');
        }
    }
    ,


    async attempt({ commit, state, dispatch }, token) {

        if (token) {
            commit('setToken', token)
        }

        if (!state.token) {
            return
        }
        try {
            let response = await
                axiosInstance({
                    method: 'GET',
                    url: '/me',

                })

            if (response) {
                //    console.log(response);
                commit('setUser', { ...response.data.User });
                commit('SET_AUTH_STATUS', true)

                dispatch('getStateData');
                dispatch('getSubscribePlan');
                dispatch('getTrans');
                store.dispatch('users/getUsers');
                store.dispatch('users/getAgent');
                store.dispatch('users/getPropriete');
            
                /*  dispatch('getCompte');
                  dispatch('getPrice');
                  dispatch('getAmount');
                  dispatch('getPayment');
                  dispatch('getAlert');
                  dispatch('getRecette');
                
                  ;
                  store.dispatch('posts/getPost');*/


            }





        } catch (error) {

            //   console.log(error)
            commit('setToken', null)
            commit('setUser', null)
            commit('SET_AUTH_STATUS', false)

        }
    }, async logout({ commit }) {

        try {
            let response = await
                axiosInstance({
                    method: 'GET',
                    url: '/user/logout',
                })

            localStorage.removeItem('_token')
            commit('setToken', null)
            commit('setUser', null)
            commit('SET_AUTH_STATUS', false)



        } catch (error) {
            localStorage.removeItem('_token')
            commit('setToken', null)
            commit('setUser', null)
            commit('SET_AUTH_STATUS', false)


        }




    },
    async getStateData({ commit }) {
        try {
            let response = await
                axiosInstance({
                    method: 'GET',
                    url: '/admin/userStatic',
                })
            if (response) {
                console.log(response);
                commit('setStatic', response.data)
            }

        } catch (error) {
            console.log(error)
        }
    },
    async getSubscribePlan({ commit }) {
        try {
            let response = await
                axiosInstance({
                    method: 'GET',
                    url: '/admin/abonnement',
                })
            if (response) {
                console.log(response);
                commit('setSuscribe', response.data.subscribe)
            }

        } catch (error) {
            console.log(error)
        }
    },async getTrans({ commit }) {
        try {
            let response = await
                axiosInstance({
                    method: 'GET',
                    url: '/admin/transaction',
                })
            if (response) {
                console.log(response);
                commit('setTrans', response.data.transactions)
            }

        } catch (error) {
            console.log(error)
        }
    }, async activeProperty({ commit }, id) {
        try {
            let response = await
                axiosInstance({
                    method: 'POST',
                    url: '/admin/activeProperty',
                    data: id
                })
            if (response) {
                //  console.log(response);
                //   commit('setPromotion', response.data)
                return response;
            }

        } catch (error) {
            console.log(error)
        }
    },async disableProperty({ commit }, id) {
        try {
            let response = await
                axiosInstance({
                    method: 'POST',
                    url: '/admin/disableProperty',
                    data: id
                })
            if (response) {
                //  console.log(response);
                //   commit('setPromotion', response.data)
                return response;
            }

        } catch (error) {
            console.log(error)
        }
    },


}