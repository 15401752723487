export default {


    data: null,
    error:null,
    token:null,
    user:null,
    static:null,
    isLoggedIn:false,
   
    data:null,
    subscribe:null,
    transactions:null
   
}