import axiosInstance from "@/plugins/axios";

export default {


    async getPost({commit}){
        try {
            const response = await axiosInstance.get("/admin/posts")
          
          // console.log(response)
         commit("setPost", {...response.data.data})
        } catch (error) {
            console.log(error)
            
        }
    

    },
    async addPost({commit}, posts){
        try {
            const response = await axiosInstance.post("/admin/post/create", posts,{'Content-Type': 'multipart/form-data', } )
          
            
     //   console.log(response)
        return response
        } catch (error) {
            console.log(error)
            
        }
    

    },
    async deletePost({commit}, id){
    
        try {
            let response = await
            axiosInstance({
                method: 'DELETE',
                url: '/admin/delete/post',
                data: {    "post_id": id }
            })
          
           // console.log(response)
       
        return response
        } catch (error) {
            console.log(error)
            
        }
    }
   
   
}