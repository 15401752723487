import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store'
const routes = [
  {
    path: '/auth',
    name: 'auth',
    component: () => import(/* webpackChunkName: "login" */ '../views/Auth.vue'),
    beforeEnter: (to, from, next) => {
      let auth = store.state.data.isLoggedIn;
      if (auth) {
        next('/admin')
      }
      next()
    }

  },
  {
    path: '/admin',
    name: 'admin',
    redirect: '/',
    component: () => import(/* webpackChunkName: "admin base" */ '../views/Admin.vue'),
    meta: {
      requiresAuth: true // Ajoutez cette ligne pour exiger l'authentification
    },
    children: [
      {
        path: '/',
        name: 'home',
        component: HomeView
      },
      {
        path: '/users',
        name: 'users',
        component: () => import(/* webpackChunkName: "user admin" */ '../views/customer/Users.vue')
      },
      {
        path: '/agents',
        name: 'agents',
        component: () => import(/* webpackChunkName: "stats" */ '../views/agent/Users.vue')
      },
      {
        path: '/propriete',
        name: 'propriete',
        component: () => import(/* webpackChunkName: "pots" */ '../views/propriete/Promotion.vue')
      }, 
      {
        path: '/suscribe',
        name: 'suscribe',
        component: () => import(/* webpackChunkName: "pots" */ '../views/stats/Promotion.vue')
      }, 
      {
        path: '/historyofscribeplan',
        name: 'historyofscribeplan',
        component: () => import(/* webpackChunkName: "pots" */ '../views/payment/Payment.vue')
      }, 
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/other/404.vue')
  }
]


const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.data.isLoggedIn) {
    next('/auth') // Redirige vers la page de connexion si l'utilisateur n'est pas connecté
  } else {
    next() // Permet d'accéder à la route
  }
})



export default router


