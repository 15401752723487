export default{

    ///get post object length
    
    getTotalPost(state){
        
        if (state.posts) {
            return Object.keys(state.posts).length;

        }
        return 'en cours...'
      
    }
}