import axios from "axios";

const BASE_URL = 'https://nbbcimmoapi.com/api';

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: { 'accept': 'application/json' }
});

async function refreshToken(oldToken) {
    try {
        const response = await axiosInstance.get('/refreshToken', {
            headers: { 'Authorization': `Bearer ${oldToken}` }
        });
        const newToken = response.data.token;

        // Mettre à jour le token dans l'instance Axios et le stockage local
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
        localStorage.setItem('_token', newToken);

        return newToken;
    } catch (error) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer null`;
        localStorage.setItem('_token', null);
     

        console.error('Error refreshing token:', error);
        // Gérer l'erreur (par exemple, rediriger vers la page de connexion)
        return null;
    }
}


axiosInstance.interceptors.response.use(
    response => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.config.url != "/refreshToken" && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            if (originalRequest.headers['Authorization']?.split(" ")[1] && (originalRequest.headers['Authorization']?.split(" ")[1] != null || originalRequest.headers['Authorization']?.split(" ")[1] !== 'null')) {
                const oldToken = originalRequest.headers['Authorization']?.split(" ")[1];
                const newToken = await refreshToken(oldToken)
              

                if (newToken) {
                    originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                    return axiosInstance(originalRequest);
                }
            }

        }
        /* if(originalRequest)
        }*/
        // Propager les autres erreurs
        return Promise.reject(error);
    }
);



export default axiosInstance;
