import { createStore } from 'vuex'
import data from './data'
import users from './users'
import posts from './posts'

import myPlugin from './suscribe'
export default createStore({
 
  modules: {
    data,
    users,
    posts
  },
  plugins: [myPlugin]
})
