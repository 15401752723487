export default {

    //users length
    getTotalUser(state) {

        if (state.users) {
            return Object.keys(state.users).length;

        }
        return 'en cours...'
    },
    //getTotalUserLastMounth

    getTotalUserLastMonth: (state) => {
        if (state.users) {
            const currentDate = new Date();
            const lastMonthDate = new Date();
            lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);

            const users = Object.values(state.users);
            return users.filter(user => {
                const userCreatedAt = new Date(user.createdAt);
                return userCreatedAt > lastMonthDate && userCreatedAt <= currentDate;
            }).length;
        }
        return 'en cours...'
    },

    getUserActive(state) {
        if (state.users) {
            const users = Object.values(state.users);
            return users.filter(user => {
                return user.status === 'active';
            }).length;
        }
        return 'en cours...'
    }
}