import axiosInstance from "@/plugins/axios";

const myPlugin = (store) => {
    // called when the store is initialized
    
    store.subscribe((mutation) => {
      if (mutation.type === 'data/setToken') {
        if (mutation.payload) {
          axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`;
          /* coinPay.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`; */
        } else {
          axiosInstance.defaults.headers.common['Authorization'] = null;
          localStorage.setItem('_token', null);
        }
      }
      
    })
  }

  export default myPlugin