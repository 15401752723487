export default {
    
   setUsers(state, users) {
   
       state.users = users
       
   },
   setAgent(state, data) {
       state.agents = data
   },
   setPropriete(state, data) {
    state.properties = data
}
}