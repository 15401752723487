export default {
    
    setError(state, error) {
        state.error = error;
      },

     
      setToken(state, token) {
        state.token = token;
         localStorage.setItem('_token', token);

    },
    setUser(state, user){
        state.user = user
    },
    SET_AUTH_STATUS(state, isAuthenticated) {
        state.isLoggedIn = isAuthenticated
      },
      setStatic(state, data){
        state.static = data
      },
      setSuscribe(state, data){
        state.subscribe = data
      },setTrans(state,data){
        state.transactions = data
      }
  
  
}