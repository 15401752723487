<template>
    <div class="nk-content ">
        <div class="container-fluid">
            <div class="nk-content-inner">
                <div class="nk-content-body">
                    <div class="nk-block-head nk-block-head-sm">
                        <div class="nk-block-between">
                            <div class="nk-block-head-content">
                                <h4 class="nk-block-title page-title">Dashboard</h4>
                            </div><!-- .nk-block-head-content -->
                        </div><!-- .nk-block-between -->
                    </div><!-- .nk-block-head -->
                    <div class="nk-block">
                        <div class="row g-gs">
                            <div class="col-xxl-4 col-md-6">
                                <div class="card is-dark h-100">
                                    <div class="nk-ecwg nk-ecwg1">
                                        <div class="card-inner">
                                            <div class="card-title-group">
                                                <div class="card-title">
                                                    <h6 class="title">TOTAL D'UTILISATEUR</h6>
                                                </div>
                                                <div class="card-tools">
                                                    <a href="#" class="link"></a>
                                                </div>
                                            </div>
                                            <div class="data">
                                                <div class="amount" v-if="statistique">{{ statistique.userNombre }}</div>
                                               
                                            </div>
                                            <div class="data">
                                                <h6 class="sub-title">agent total</h6>
                                                <div class="data-group">
                                                    <div class="amount " v-if="statistique">{{ statistique.agentNombre }}</div>

                                                </div>
                                            </div>
                                        </div><!-- .card-inner -->
                                        <div class="nk-ecwg1-ck">
                                            <canvas class="ecommerce-line-chart-s1" id="totalSales"></canvas>
                                        </div>
                                    </div><!-- .nk-ecwg -->
                                </div><!-- .card -->
                            </div><!-- .col -->
                          
                            <div class="col-xxl-4 col-md-6" v-if="statistique">
                                <div class="card h-100">
                                    <div class="nk-ecwg nk-ecwg2">
                                        <div class="card-inner">
                                            <div class="card-title-group mt-n1">
                                                <div class="card-title">
                                                    <h6 class="title">totale de propriete</h6>
                                                </div>

                                            </div>
                                            <div class="data">
                                                <div class="data-group">
                                                    <div class="amount">{{ statistique.sellProprety +  statistique.rentProprety}}</div>

                                                </div>
                                            </div>
                                            <div class="data">
                                                
                                                <h6 class="sub-title">à louer total</h6>
                                                <div class="data-group"  >
                                                    <div class="amount">{{ statistique.rentProprety}} </div>

                                                </div>
                                                <h6 class="sub-title"> à vendre total </h6>
                                                <div class="data-group"  >
                                                    <div class="amount">{{ statistique.sellProprety }} </div>

                                                </div>
                                               
                                            </div>

                                        </div><!-- .card-inner -->

                                    </div><!-- .nk-ecwg -->
                                </div><!-- .card -->
                            </div><!-- .col -->



                            <div class="col-xxl-6 col-md-6">
                                <div class="card h-100">

                                    <bar-chart :data="chartData" v-if="users" />

                                </div><!-- .card -->
                            </div><!-- .col -->
                            <div class="col-xxl-6 col-md-6">
                                <div class="card h-100">

                                    <bar-chart :data="chartDataAgent" v-if="agents" />

                                </div><!-- .card -->
                            </div><!-- .col -->
                            <div class="col-xxl-6">
                                <div class="card card-full">
                                    <div class="card-inner">
                                        <div class="card-title-group">
                                            <div class="card-title">
                                                <h6 class="title">Recent propriete</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="nk-tb-list mt-n2">
                                        <div class="nk-tb-item nk-tb-head">
                                            <div class="nk-tb-col"><span>Order No.</span></div>
                                            <div class="nk-tb-col tb-col-sm"><span>Promotion</span></div>
                                            <div class="nk-tb-col tb-col-md"><span>start day</span></div>
                                            <div class="nk-tb-col"><span>end day</span></div>
                                            <div class="nk-tb-col"><span class="d-none d-sm-inline">valeur</span></div>
                                        </div>

                                        <div class="nk-tb-item" v-for="promo in recentPromotion" :key="promo.id">
                                            <div class="nk-tb-col">
                                                <span class="tb-lead"><a href="#">#Pr</a></span>
                                            </div>
                                            <div class="nk-tb-col tb-col-sm">
                                                <div class="user-card">

                                                    <div class="user-name">
                                                        <span class="tb-lead">{{ promo.reward_type }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="nk-tb-col tb-col-md">
                                                <span class="tb-sub">{{ formatDate(promo.start_date) }}</span>
                                            </div>
                                            <div class="nk-tb-col">
                                                <span class="tb-sub tb-amount">{{ formatDate(promo.end_date) }}</span>
                                            </div>
                                            <div class="nk-tb-col">
                                                <span class="badge badge-dot badge-dot-xs bg-success">{{ promo.reward_value
                                                }}</span>
                                            </div>
                                        </div>

                                    </div>
                                </div><!-- .card -->
                            </div>
                            <div class="col-xxl-6">
                                <div class="card card-full">
                                    <div class="card-inner">
                                        <div class="card-title-group">
                                            <div class="card-title">
                                                <h6 class="title">Recent inscription d'agent</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="nk-tb-list mt-n2">
                                        <div class="nk-tb-item nk-tb-head">
                                            <div class="nk-tb-col"><span>Order No.</span></div>
                                            <div class="nk-tb-col tb-col-sm"><span>Promotion</span></div>
                                            <div class="nk-tb-col tb-col-md"><span>start day</span></div>
                                            <div class="nk-tb-col"><span>end day</span></div>
                                            <div class="nk-tb-col"><span class="d-none d-sm-inline">valeur</span></div>
                                        </div>

                                        <div class="nk-tb-item" v-for="promo in recentPromotion" :key="promo.id">
                                            <div class="nk-tb-col">
                                                <span class="tb-lead"><a href="#">#Pr</a></span>
                                            </div>
                                            <div class="nk-tb-col tb-col-sm">
                                                <div class="user-card">

                                                    <div class="user-name">
                                                        <span class="tb-lead">{{ promo.reward_type }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="nk-tb-col tb-col-md">
                                                <span class="tb-sub">{{ formatDate(promo.start_date) }}</span>
                                            </div>
                                            <div class="nk-tb-col">
                                                <span class="tb-sub tb-amount">{{ formatDate(promo.end_date) }}</span>
                                            </div>
                                            <div class="nk-tb-col">
                                                <span class="badge badge-dot badge-dot-xs bg-success">{{ promo.reward_value
                                                }}</span>
                                            </div>
                                        </div>

                                    </div>
                                </div><!-- .card -->
                            </div>
                        </div><!-- .row -->
                    </div><!-- .nk-block -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import BarChart from '@/components/BarChart.vue'
import { mapGetters, mapState } from 'vuex'


export default {

    components: { BarChart },
    data() {
        return {
            chartDatas: {
                labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                stacked: true,

                datasets: [{
                    label: "urgence",
                    backgroundColor: "#017efa",
                    data: [0]
                }],

            },

        }
    }, computed: {
        ...mapGetters({
          

        }),

        ...mapState({
            statistique: state => state.data.static,
          
            users: state => state.users.users,
            agents: state =>  state.users.agents
        }),
        recentPromotion() {
            if (this.promotion) {
                const promotionsArray = Object.values(this.promotion);
                promotionsArray.sort((a, b) => new Date(b.date) - new Date(a.date));

                // Renvoyez les 5 dernières promotions
                const dernieresPromotions = promotionsArray.slice(0, 5);

                return dernieresPromotions;
            }

            return null


        }, chartData() {
            if (this.users) {

                const usersByMonth = Array(12).fill(0);

                for (const key in this.users) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (this.users.hasOwnProperty(key)) {
                        const user = this.users[key];
                        const createdDate = new Date(user.createdAt);
                        const monthIndex = createdDate.getMonth();
                        usersByMonth[monthIndex]++;
                    }
                }



                return {
                    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                    datasets: [{
                        label: "Utilisateurs inscrits",
                        backgroundColor: "#017efa",
                        data: usersByMonth
                    }]
                };

            }
            return null
        },
        chartDataAgent() {
            if (this.agents) {

                const usersByMonth = Array(12).fill(0);

                for (const key in this.agents) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (this.agents.hasOwnProperty(key)) {
                        const user = this.agents[key];
                        const createdDate = new Date(user.createdAt);
                        const monthIndex = createdDate.getMonth();
                        usersByMonth[monthIndex]++;
                    }
                }



                return {
                    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                    datasets: [{
                        label: "agents inscrits",
                        backgroundColor: "#017efa",
                        data: usersByMonth
                    }]
                };

            }
            return null
        }
    },
    methods: {
        formatDate(value) {
            const date = new Date(value);
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return date.toLocaleDateString('fr-FR', options);
        },
        updateChartData() {
            // Supposons que vous ayez l'objet user avec les informations d'inscription

            if (this.users) {
                // Créez un tableau pour compter le nombre d'utilisateurs inscrits par mois
                const usersByMonth = Array(12).fill(0);

                // Parcourez les utilisateurs et comptez le nombre d'utilisateurs inscrits par mois
                this.users.forEach(user => {
                    const createdDate = new Date(user.created_at);
                    const monthIndex = createdDate.getMonth();
                    usersByMonth[monthIndex]++;
                });

                // Mettez à jour les données du graphique avec les utilisateurs inscrits par mois
                this.chartData.datasets[0].data = usersByMonth;
            }


        }
    },
}
</script>

<style></style>
