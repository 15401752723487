import axiosInstance from "@/plugins/axios";

export default {


    async getUsers({ commit }) {
        try {
            const response = await axiosInstance.get("/admin/users")

            commit("setUsers", { ...response.data.users })
        } catch (error) {
            console.log(error)

        }


    }, async getAgent({ commit }) {
        try {
            const response = await axiosInstance.get("/admin/agents")

            commit("setAgent", { ...response.data.agents })
        } catch (error) {
            console.log(error)

        }


    },
    async getPropriete({ commit }) {
        try {
            const response = await axiosInstance.get("/admin/property")

            commit("setPropriete", { ...response.data.properties })
        } catch (error) {
            console.log(error)

        }


    }, async validAgent({ commit }, id) {
        try {
            let response = await
                axiosInstance({
                    method: 'POST',
                    url: '/admin/validAgent',
                    data: id
                })
            if (response) {
                //  console.log(response);
                //   commit('setPromotion', response.data)
                return response;
            }

        } catch (error) {
            console.log(error)
        }
    },
    async addSuscribe({ commit }, data) {
        try {
            let response = await
                axiosInstance({
                    method: 'POST',
                    url: '/admin/addSuscribe',
                    data: data
                })
            if (response) {
                 console.log(response);
                //   commit('setPromotion', response.data)
                return true;
            }

        } catch (error) {
            throw new Error('Existe deja');
            console.log(error)
        }
    },async disableAgent({ commit }, id) {
        try {
            let response = await
                axiosInstance({
                    method: 'POST',
                    url: '/admin/disableAgent',
                    data: id
                })
            if (response) {
                //  console.log(response);
                //   commit('setPromotion', response.data)
                return response;
            }

        } catch (error) {
            console.log(error)
        }
    },
    async activeAgent({ commit }, data) {
        try {
            let response = await
                axiosInstance({
                    method: 'POST',
                    url: '/admin/activeAgent',
                    data: data
                })
            if (response) {
                //  console.log(response);
                //   commit('setPromotion', response.data)
                return response;
            }

        } catch (error) {
            console.log(error)
        }
    },

}