
<template>
    <Bar id="my-chart-id" :options="chartOptions" :data="data" :style="{ width: '100%', height: '100%' }" />
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
export default {


    components: { Bar },
    data() {
        return {
          
            chartOptions: {

                maintainAspectRatio: false,
                tooltips: {
                    enabled: true,
                    mode: 'index',
                    backgroundColor: '#eff6ff',
                    titleFontSize: 13,
                    titleFontColor: '#6783b8',
                    titleMarginBottom: 6,
                    bodyFontColor: '#9eaecf',
                    bodyFontSize: 12,
                    bodySpacing: 4,
                    yPadding: 10,
                    xPadding: 10,
                    footerMarginTop: 0,
                    displayColors: false
                },
                scales: {
                    y: {
                        display: true,


                        ticks: {
                            beginAtZero: true,
                            fontSize: 12,
                            fontColor: '#9eaecf'
                        },
                        gridLines: {

                            tickMarkLength: 0,

                        }
                    },
                    x: {
                        display: true,

                        ticks: {
                            fontSize: 12,
                            fontColor: '#9eaecf',
                            source: 'auto',

                        },
                        gridLines: {
                            color: "transparent",
                            tickMarkLength: 10,
                            zeroLineColor: 'transparent'
                        }
                    }
                }
            }
        }
    },props:['data']
}
</script>
