<template>
  <div class="nk-app-root">
    <!-- main @s -->
    <div class="nk-main ">
      <router-view></router-view>
    </div>
  </div>
</template>



<style>

.bg-e46{
    background-color: #142868 !important;
}
</style>