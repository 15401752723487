

import'./assets/css/dashlite.css?ver=3.1.2'
import'./assets/css/theme.css?ver=3.1.2'

import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'



let app = createApp(App);

store.dispatch('data/attempt', localStorage.getItem('_token')).then(() => {
    app.component('VueDatePicker', VueDatePicker);

    app.use(router).use(store).mount('#app')

})